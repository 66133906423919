/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
              // JavaScript to be fired on all pages 

              var nScrollStart = 0;
              $(document).scroll(function() {
                nScrollStart = $(this).scrollTop();
                if (nScrollStart >= 30) {
                  $("#header").addClass("fixed");
                  $("body").addClass("menu-fixed");
                } else {
                  $("#header").removeClass("fixed");
                  $("body").removeClass("menu-fixed");
                }
              });      



              $("#toc_container").before( "<div class='toc_mobile change'><div class='bar1'></div><div class='bar2'></div><div class='bar3'></div></div>" );
             

              $( ".toc_mobile" ).click(function() {
                $( "#toc_container.fixed" ).toggle( "slow", function() {
                  // Animation here
                });
                var selected = $(this);
                if( selected.hasClass('change') ) {
                  selected.removeClass('change');
                } else {
                  selected.addClass('change');
                }
              });


              var TOCContainer_selected = $('#toc_container'); //targetName is not  jQuery object
              var TOCCommentPost_selected = $('#CommentPost'); //targetName is not  jQuery object

              if (TOCContainer_selected.length) {
                var TOCContainer = TOCContainer_selected.offset().top; //targetName is not  jQuery object
                var TOCCommentPost = TOCCommentPost_selected.offset().top; //targetName is not  jQuery object

                $(document).scroll(function() {   
                  nScrollStart = $(this).scrollTop();  
                  if (nScrollStart >= TOCContainer ) {   
                    $("#toc_container").addClass("fixed");
                    //$(".toc_mobile").addClass("show");

                    if (nScrollStart >= TOCCommentPost ) {   
                      $("#toc_container").removeClass("fixed");
                      //$(".toc_mobile").removeClass("show");
                    } else {
                      $("#toc_container").addClass("fixed");
                      //$(".toc_mobile").addClass("show");
                    }
                  } else {
                    $("#toc_container").removeClass("fixed");
                    //$(".toc_mobile").removeClass("show");
                  }
                });
              }   
      

              function CounterCategories(event) {
                  var element   = event.target;           // DOM element, in this example .owl-carousel
                  var items     = event.item.count;       // Number of items
                  //var item      = event.item.index;   // Position of the current item
                  var item      = event.item.index + 4;   // Position of the current item
                  var slideBy = Math.trunc( event.item.count / 3 );  // Number of items and divide by 3
                  var isTrigger  = event.isTrigger;       
                  var pages  = event.page.size;   
                  var page  = event.page.count + 1;   
                  //$('#CounterAccessories').html(item+" / "+slideBy);
                  $('#CounterCategories').html(item+" / "+items);  

                  //console.log(event);
              }   

              function CounterShop(event) {
                  var element   = event.target;           // DOM element, in this example .owl-carousel
                  var items     = event.item.count;       // Number of items
                  var item      = event.item.index + 3;   // Position of the current item
                  var slideBy = Math.trunc( event.item.count / 3 ); // Number of items and divide by 3

                  $('#CounterShop').html(item+" / "+items);

                   //console.log(event);
              }     
              
              var OwlCategories = $(".owl-categories");  
              OwlCategories.owlCarousel({
                    autoplay: false,  
                    items: 1, // THIS IS IMPORTANT 
                    //slideBy: 4,
                    slideSpeed: 300,
                    dots: false,
                    loop: false,
                    margin: 44,   
                    nav: true,
                    navText: [
                          '',
                          ''
                    ],
                    onInitialized  : CounterCategories, //When the plugin has initialized.
                    onTranslated : CounterCategories, //When the translation of the stage has finished.
                    responsive : {
                      // breakpoint from 0 up
                      0 : {
                          items: 1
                      },
                      // breakpoint from 768 up
                      768 : {
                          items: 2
                      },
                      // breakpoint from 992 up
                      992 : {
                          items: 3
                      },
                      // breakpoint from 1200 up
                      1200 : {
                          items: 4
                      }
                  }
              });  

              var OwlShop = $(".owl-shop");  
              OwlShop.owlCarousel({
                    autoplay: false,  
                    items: 1, // THIS IS IMPORTANT 
                    //slideBy: 3,
                    slideSpeed: 300,
                    dots: false,
                    loop: false,  
                    margin: 85,   
                    nav: true,
                    navText: [
                          '',
                          ''
                    ],
                    onInitialized  : CounterShop, //When the plugin has initialized.
                    onTranslated : CounterShop, //When the translation of the stage has finished.
                    responsive : {
                      // breakpoint from 0 up
                      0 : {
                          items: 1
                      },
                      // breakpoint from 768 up
                      575 : {
                          items: 2,
                          margin: 15 
                      },
                      // breakpoint from 768 up
                      767 : {
                          items: 2,
                          margin: 25 
                      },
                      // breakpoint from 768 up
                      992 : {
                          items: 3
                      }
                  }
              });  

              var OwlRecipe = $(".owl-recipe");
                OwlRecipe.owlCarousel({
                  autoplay: false,  
                  items: 1, // THIS IS IMPORTANT 
                  slideBy: 1,
                  slideSpeed: 300,
                  dots: false,
                  loop: false,
                  margin: 0,   
                  nav: true,
                  animateIn: 'fadeIn', // add this
                  animateOut: 'fadeOut', // and this
                  navText: [
                        '',
                        ''
                  ]  
              });  

              var OwlRecipeItems = $(".owl-recipe-items");
                OwlRecipeItems.owlCarousel({
                  autoplay: false,     
                  items: 1, // THIS IS IMPORTANT 
                  slideBy: 1,
                  slideSpeed: 300,    
                  dots: false,
                  loop: false,
                  touchDrag: false,
                  mouseDrag: false,
                  margin: 0,   
                  nav: true,
                  animateIn: 'fadeIn', // add this
                  animateOut: 'fadeOut', // and this
                  //transitionStyle : 'fade',
                  navText: [
                        '',
                        ''
                  ]  
              });  

              // Toogle Class accordion 
              function toggleIcon(e) {
                $(e.target)
                    .prev('.btn-link')
                    .find(".fa")
                    .toggleClass('fa-plus fa-minus');
              }
              $('.card').on('hidden.bs.collapse', toggleIcon);
              $('.card').on('shown.bs.collapse', toggleIcon);


              // If click then display comment form
              $('.wrap-comment-btn .btn').on('click', function(event) {
                $('#respond').addClass('d-block');
              });
              $('.ProductRespond').on('click', function(event) {
                $('#respond').addClass('d-block');
              });

              // Click trigger carousel
              $('#CategoriesCarousel .items .owl-carousel .owl-nav button.owl-prev').on('click', function(event) {
                $('#CategoriesCarousel .sticky .owl-recipe-items .owl-nav .owl-prev').trigger('click');
              });
              $('#CategoriesCarousel .items .owl-carousel .owl-nav button.owl-next').on('click', function(event) {
                $('#CategoriesCarousel .sticky .owl-recipe-items .owl-nav .owl-next').trigger('click');
              });

              // Effect tooltips
              $( "#ProductEffects .heading .effects-info p .info-icon" ).hover(
                function() {
                  $( ".info-tooltips" ).addClass( "hover" );
                }, function() {  
                  $( ".info-tooltips" ).removeClass( "hover" );     
                }
              );

              // Variation click trigger select and changes value
              $('.variation-radios .variatioon_radio input').on('click', function(event) {
                $('select[name="'+$(this).attr('name')+'"]').val($(this).val()).trigger('change');
              });

              // Variation click addClass
              $(".variation-radios .variatioon_radio").click(function() { 
                $('.variation-radios .variatioon_radio').removeClass('active');
                $(this).addClass('active');
              }); 

              $('.pushy ul li a').click(function () {
                $(this).toggleClass('open');
                $(this).next().toggleClass('open');
              });
                
            },  
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        }, 
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
